import React from "react";
import theme from "theme";
import { Theme, Link, Image, Strong, Structure, Text, Box, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				מפגש ברלין
			</title>
			<meta name={"description"} content={"מפגש ברלין - מסעדת המבורגרים ז'בוטינסקי 61 רמת גן"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/639068b75f45170024239f08/images/d30fe4d2d9faa0c3b7d882546672799889b0df11.00000710.png?v=2023-01-07T11:19:42.792Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/639068b75f45170024239f08/images/d30fe4d2d9faa0c3b7d882546672799889b0df11.00000710.png?v=2023-01-07T11:19:42.792Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/639068b75f45170024239f08/images/d30fe4d2d9faa0c3b7d882546672799889b0df11.00000710.png?v=2023-01-07T11:19:42.792Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/639068b75f45170024239f08/images/d30fe4d2d9faa0c3b7d882546672799889b0df11.00000710.png?v=2023-01-07T11:19:42.792Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/639068b75f45170024239f08/images/d30fe4d2d9faa0c3b7d882546672799889b0df11.00000710.png?v=2023-01-07T11:19:42.792Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/639068b75f45170024239f08/images/d30fe4d2d9faa0c3b7d882546672799889b0df11.00000710.png?v=2023-01-07T11:19:42.792Z"} />
		</Helmet>
		<Structure cells-number-total="4" cells-number-group="4" color="#FF7E00" background="#FF7E00">
			<Override
				slot="Content"
				grid-template-columns="repeat(4, 3fr)"
				md-grid-template-columns="repeat(2, 6fr)"
				sm-grid-template-columns="12fr"
				justify-items="center"
				align-items="center"
				sm-display="flex"
				sm-flex="0 1 auto"
				sm-width="fit-content"
				sm-min-width="100%"
				sm-order="-1"
				sm-flex-direction="row-reverse"
				sm-justify-content="space-around"
				sm-flex-wrap="wrap-reverse"
			>
				<Override slot="cell-3">
					<Image
						src="https://uploads.quarkly.io/639068b75f45170024239f08/images/1915281_671965359612032_5443332803695679626_n.png?v=2022-12-07T10:23:39.571Z"
						width="150px"
						text-align="right"
						srcSet="https://smartuploads.quarkly.io/639068b75f45170024239f08/images/1915281_671965359612032_5443332803695679626_n.png?v=2022-12-07T10%3A23%3A39.571Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/1915281_671965359612032_5443332803695679626_n.png?v=2022-12-07T10%3A23%3A39.571Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/1915281_671965359612032_5443332803695679626_n.png?v=2022-12-07T10%3A23%3A39.571Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/1915281_671965359612032_5443332803695679626_n.png?v=2022-12-07T10%3A23%3A39.571Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/1915281_671965359612032_5443332803695679626_n.png?v=2022-12-07T10%3A23%3A39.571Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/1915281_671965359612032_5443332803695679626_n.png?v=2022-12-07T10%3A23%3A39.571Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/1915281_671965359612032_5443332803695679626_n.png?v=2022-12-07T10%3A23%3A39.571Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Override>
				<Override slot="cell-1" />
				<Override slot="cell-0">
					<Link
						href="https://www.facebook.com/mifgashberlin"
						color="#000000"
						link-color="#ffffff"
						font="normal 400 20px/1.5 --fontFamily-googleAssistant"
						text-decoration-line="initial"
					>
						INSTAGRAM{"      "}
					</Link>
					<Link
						href="https://www.facebook.com/mifgashberlin"
						color="#000000"
						link-color="#ffffff"
						font="normal 400 20px/1.5 --fontFamily-googleAssistant"
						text-decoration-line="initial"
					>
						FACEBOOK
					</Link>
				</Override>
				<Override slot="Cell 0th" width="fit-content" height="50px" sm-width="fit-content" />
				<Override slot="Cell 1st" width="fit-content" height="50px" sm-width="fit-content" />
				<Override slot="Cell 2nd" width="100px" border-color="rgba(255, 126, 0, 0)" sm-width="fit-content" />
				<Override slot="cell-2">
					<Link href="/accessibility-statement" color="#000000" sm-color="#ffffff">
						<Strong color="#ffffff">
							הצהרת נגישות
						</Strong>
					</Link>
				</Override>
			</Override>
		</Structure>
		<Section
			padding="20px 0 20px 0"
			background="url(https://uploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10:23:35.459Z) center center/cover no-repeat"
			min-height="25vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
			height="171.817px"
			quarkly-title="top title"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" height="128.817px" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-end"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				height="110.817px"
			>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="normal 700 38px/1.2 --fontFamily-googleAssistant"
					lg-text-align="center"
					text-align="center"
				>
					מפגש ברלין
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="normal 700 38px/1.2 --fontFamily-googleAssistant"
					lg-text-align="center"
					text-align="center"
				>
					ז'בוטינסקי 61 רמת גן
				</Text>
			</Box>
		</Section>
		<Section padding="10px 0 10px 0" sm-padding="60px 0 60px 0" quarkly-title="חלק עליון" display="block">
			<Override
				slot="SectionContent"
				min-width="fit-content"
				height="fit-content"
				max-width="fit-content"
				min-height="fit-content"
				flex="0 0 auto"
			/>
			<Text
				margin="0px 0px 0px 0px"
				border-color="#FF7E00"
				font="400 36px --fontFamily-googleAssistant"
				color="#000"
				padding="0px 0px 10px 0px"
				dir="rtl"
				text-align="right"
				height="fit-content"
				sm-text-align="center"
			>
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					{"\n"}תפריט המבורגרים וארוחות של מפגש ברלין
				</Strong>
			</Text>
			<Text
				margin="0px 0px 0px 0px"
				border-color="#FF7E00"
				font="400 20px --fontFamily-googleAssistant"
				color="#000"
				padding="0px 0px 10px 0px"
				dir="rtl"
				text-align="right"
				height="fit-content"
				sm-text-align="center"
			>
				{"\n\n\n\n"}איסוף עצמי חינם · זמן הכנה כ-25 דק
			</Text>
			<Text
				margin="0px 0px 0px 0px"
				text-align="right"
				border-color="#FF7E00"
				font="400 20px --fontFamily-googleAssistant"
				color="#000"
				padding="0px 0px 10px 0px"
				direction="rtl"
				dir="rtl"
				height="fit-content"
				sm-text-align="center"
			>
				23:00 – 11:00 * כשר למהדרין
			</Text>
		</Section>
		<Section
			padding="30px 0 80px 0"
			sm-padding="60px 0 60px 0"
			quarkly-title="main"
			justify-content="space-around"
			md-width="fit-content"
			md-display="grid"
			sm-display="inline"
			flex-direction="column"
			align-items="flex-start"
		>
			<Override
				slot="SectionContent"
				md-flex="0 1 auto"
				flex="0 1 auto"
				order="-1"
				md-justify-self="center"
				md-align-self="center"
				md-flex-direction="column"
				md-align-items="flex-end"
			/>
			<Section padding="80px 0 80px 0" margin="0px 0px 0px 0px" background="--color-light">
				<Override
					slot="SectionContent"
					flex-direction="row"
					flex-wrap="wrap"
					display="inline"
					position="relative"
					top="-172px"
				/>
				<Box
					display="flex"
					align-items="center"
					flex-direction="column"
					justify-content="center"
					margin="0px 0px 60px 0px"
					width="100%"
					sm-margin="0px 0px 30px 0px"
					padding="0px 200px 0px 200px"
					lg-padding="0px 0px 0px 0px"
				/>
				<Box
					display="grid"
					flex-wrap="wrap"
					width="100%"
					align-items="center"
					justify-content="center"
					grid-gap="30px"
					grid-template-columns="repeat(2, 1fr)"
					md-grid-template-columns="1fr"
				>
					<Box
						width="100%"
						display="flex"
						padding="48px 40px 56px 40px"
						background="--color-light url(https://uploads.quarkly.io/639068b75f45170024239f08/images/54654.jpg?v=2022-12-14T09:44:16.467Z)"
						border-radius="24px"
						align-items="center"
						justify-content="center"
						lg-padding="48px 35px 56px 35px"
						border-style="solid"
						border-color="--color-lightD2"
						box-shadow="0px 0px 10px 0px rgb(0 0 0 / 50%)"
						border-width="1px"
						flex-direction="column"
						sm-align-items="stretch"
						sm-justify-content="center"
						top="-200px"
					>
						<Text
							color="--darkL2"
							font="normal 700 24px/1.2 --fontFamily-googleAssistant"
							lg-text-align="left"
							quarkly-title="ארוחת"
							flex="0 0 auto"
							width="auto"
							margin="10px 0px 16px 0px"
							text-align="center"
							display="grid"
							align-self="flex-end"
						>
							המבורגר 200 גרם עשוי מבשר בקר משובח
							<br />
							{"\n\n"}
						</Text>
						<Image
							src="https://uploads.quarkly.io/639068b75f45170024239f08/images/4fb132f1-aef8-412f-ac66-2b8f98eb2dc5.jpg?v=2022-12-14T09:09:33.333Z"
							width="100%"
							object-fit="cover"
							height="235px"
							align-self="flex-end"
							srcSet="https://smartuploads.quarkly.io/639068b75f45170024239f08/images/4fb132f1-aef8-412f-ac66-2b8f98eb2dc5.jpg?v=2022-12-14T09%3A09%3A33.333Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/4fb132f1-aef8-412f-ac66-2b8f98eb2dc5.jpg?v=2022-12-14T09%3A09%3A33.333Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/4fb132f1-aef8-412f-ac66-2b8f98eb2dc5.jpg?v=2022-12-14T09%3A09%3A33.333Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/4fb132f1-aef8-412f-ac66-2b8f98eb2dc5.jpg?v=2022-12-14T09%3A09%3A33.333Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/4fb132f1-aef8-412f-ac66-2b8f98eb2dc5.jpg?v=2022-12-14T09%3A09%3A33.333Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/4fb132f1-aef8-412f-ac66-2b8f98eb2dc5.jpg?v=2022-12-14T09%3A09%3A33.333Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/4fb132f1-aef8-412f-ac66-2b8f98eb2dc5.jpg?v=2022-12-14T09%3A09%3A33.333Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Structure height="min-content" width="fit-content">
							<Override slot="Content">
								<Override slot="cell-0">
									<Text
										margin="0px 0px 11px 0px"
										color="--darkL2"
										font="normal 500 18px/1.2 --fontFamily-googleAssistant"
										lg-text-align="left"
										quarkly-title="  ₪46.00"
										flex="0 0 auto"
										width="auto"
										text-align="center"
										sm-align-self="flex-start"
									/>
								</Override>
								<Override slot="cell-2" />
								<Override slot="Cell 0th" width="max-content" />
								<Override slot="cell-1">
									<Text
										margin="0px 0px 48px 0px"
										color="--dark"
										text-align="center"
										font="--base"
										width="max-content"
									>
										<Strong>
											מוגש עם ציפס ושתיה לבחירה{"\n\n"}
										</Strong>
									</Text>
								</Override>
							</Override>
						</Structure>
						<Button
							background="#007a12"
							quarkly-title="077"
							flex="0 0 auto"
							font="normal 300 15px/1.5 --fontFamily-googleAssistant"
							overflow-x="visible"
							overflow-y="visible"
							padding="10px 10px 10px 10px"
							margin="10px 0px 0px 10px"
							type="link"
							href="tel:0775008966"
							sm-width="max-content"
							display="flex"
							width="max-content"
							align-self="flex-start"
						>
							להזמנות חייגו{" "}
							<br />
							077-5008966
						</Button>
					</Box>
					<Box
						width="100%"
						display="flex"
						padding="48px 40px 56px 40px"
						background="--color-light url(https://uploads.quarkly.io/639068b75f45170024239f08/images/54654.jpg?v=2022-12-14T09:44:16.467Z)"
						border-radius="24px"
						align-items="center"
						justify-content="center"
						lg-padding="48px 35px 56px 35px"
						border-style="solid"
						border-color="--color-lightD2"
						box-shadow="0px 0px 10px 0px rgb(0 0 0 / 50%)"
						border-width="1px"
						flex-direction="column"
						sm-align-items="stretch"
						sm-justify-content="center"
						top="-200px"
					>
						<Text
							color="--darkL2"
							font="normal 700 24px/1.2 --fontFamily-googleAssistant"
							lg-text-align="left"
							quarkly-title="ארוחת"
							flex="0 0 auto"
							width="auto"
							margin="10px 0px 16px 0px"
							text-align="center"
							display="grid"
							align-self="flex-end"
							id="add"
						>
							{"  "}ארוחת המבורגר בקר 200 גרם
						</Text>
						<Image
							src="https://uploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10:53:10.432Z"
							width="100%"
							object-fit="cover"
							height="235px"
							align-self="flex-end"
							srcSet="https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Structure height="min-content" width="fit-content">
							<Override slot="Content">
								<Override slot="cell-0">
									<Text
										margin="0px 0px 11px 0px"
										color="--darkL2"
										font="normal 500 18px/1.2 --fontFamily-googleAssistant"
										lg-text-align="left"
										quarkly-title="  ₪46.00"
										flex="0 0 auto"
										width="auto"
										text-align="center"
										sm-align-self="flex-start"
									/>
								</Override>
								<Override slot="cell-2" />
								<Override slot="Cell 0th" width="max-content" />
								<Override slot="cell-1">
									<Text
										margin="0px 0px 48px 0px"
										color="--dark"
										text-align="center"
										font="--base"
										width="max-content"
									>
										<Strong>
											מוגש עם ציפס ושתיה לבחירה{"\n\n"}
										</Strong>
									</Text>
								</Override>
							</Override>
						</Structure>
						<Button
							background="#007a12"
							quarkly-title="077"
							font="normal 300 15px/1.5 --fontFamily-googleAssistant"
							overflow-x="visible"
							overflow-y="visible"
							padding="10px 10px 10px 10px"
							margin="10px 0px 0px 10px"
							type="link"
							href="tel:0775008966"
							sm-width="max-content"
							width="max-content"
							align-self="flex-start"
							flex="0 0 auto"
							display="flex"
						>
							להזמנות חייגו{" "}
							<br />
							077-5008966
						</Button>
					</Box>
					<Box
						width="100%"
						display="flex"
						padding="48px 40px 56px 40px"
						background="--color-light url(https://uploads.quarkly.io/639068b75f45170024239f08/images/54654.jpg?v=2022-12-14T09:44:16.467Z)"
						border-radius="24px"
						align-items="center"
						justify-content="center"
						lg-padding="48px 35px 56px 35px"
						border-style="solid"
						border-color="--color-lightD2"
						box-shadow="0px 0px 10px 0px rgb(0 0 0 / 50%)"
						border-width="1px"
						flex-direction="column"
						sm-align-items="stretch"
						sm-justify-content="center"
						top="-200px"
					>
						<Text
							color="--darkL2"
							font="normal 700 24px/1.2 --fontFamily-googleAssistant"
							lg-text-align="left"
							quarkly-title="ארוחת"
							flex="0 0 auto"
							width="auto"
							margin="10px 0px 16px 0px"
							text-align="center"
							display="grid"
							align-self="flex-end"
						>
							{" "}ארוחת המבורגר כבש 200 גרם
							<br />
							<br />
							{"\n\n"}
						</Text>
						<Image
							src="https://uploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10:53:10.432Z"
							width="100%"
							object-fit="cover"
							height="235px"
							align-self="flex-end"
							srcSet="https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Structure height="min-content" width="fit-content">
							<Override slot="Content">
								<Override slot="cell-0">
									<Text
										margin="0px 0px 11px 0px"
										color="--darkL2"
										font="normal 500 18px/1.2 --fontFamily-googleAssistant"
										lg-text-align="left"
										quarkly-title="  ₪46.00"
										flex="0 0 auto"
										width="auto"
										text-align="center"
										sm-align-self="flex-start"
									/>
								</Override>
								<Override slot="cell-2" />
								<Override slot="Cell 0th" width="max-content" />
								<Override slot="cell-1">
									<Text
										margin="0px 0px 48px 0px"
										color="--dark"
										text-align="center"
										font="--base"
										width="max-content"
									>
										<Strong>
											מוגש עם ציפס ושתיה לבחירה{"\n\n"}
										</Strong>
									</Text>
								</Override>
							</Override>
						</Structure>
						<Button
							background="#007a12"
							quarkly-title="077"
							flex="0 0 auto"
							font="normal 300 15px/1.5 --fontFamily-googleAssistant"
							overflow-x="visible"
							overflow-y="visible"
							padding="10px 10px 10px 10px"
							margin="10px 0px 0px 10px"
							type="link"
							href="tel:0775008966"
							sm-width="max-content"
							display="flex"
							width="max-content"
							align-self="flex-start"
						>
							להזמנות חייגו{" "}
							<br />
							077-5008966
						</Button>
					</Box>
					<Box
						width="100%"
						display="flex"
						padding="48px 40px 56px 40px"
						background="--color-light url(https://uploads.quarkly.io/639068b75f45170024239f08/images/54654.jpg?v=2022-12-14T09:44:16.467Z)"
						border-radius="24px"
						align-items="center"
						justify-content="center"
						lg-padding="48px 35px 56px 35px"
						border-style="solid"
						border-color="--color-lightD2"
						box-shadow="0px 0px 10px 0px rgb(0 0 0 / 50%)"
						border-width="1px"
						flex-direction="column"
						sm-align-items="stretch"
						sm-justify-content="center"
						top="-200px"
					>
						<Text
							color="--darkL2"
							font="normal 700 24px/1.2 --fontFamily-googleAssistant"
							lg-text-align="left"
							quarkly-title="ארוחת"
							flex="0 0 auto"
							width="auto"
							margin="10px 0px 16px 0px"
							text-align="right"
							display="grid"
							align-self="flex-end"
						>
							המבורגר כבש עסיסי, 200 גרם
							<br />
							{" "}(בשר בקר ושומן בקר וכבש)
						</Text>
						<Image
							src="https://uploads.quarkly.io/639068b75f45170024239f08/images/c6dd4b7a1108b8ab170b04c351ffe22bbb18d97d.00000692.jpg?v=2023-01-07T11:19:42.762Z"
							width="100%"
							object-fit="cover"
							height="235px"
							align-self="flex-end"
							srcSet="https://smartuploads.quarkly.io/639068b75f45170024239f08/images/c6dd4b7a1108b8ab170b04c351ffe22bbb18d97d.00000692.jpg?v=2023-01-07T11%3A19%3A42.762Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/c6dd4b7a1108b8ab170b04c351ffe22bbb18d97d.00000692.jpg?v=2023-01-07T11%3A19%3A42.762Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/c6dd4b7a1108b8ab170b04c351ffe22bbb18d97d.00000692.jpg?v=2023-01-07T11%3A19%3A42.762Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/c6dd4b7a1108b8ab170b04c351ffe22bbb18d97d.00000692.jpg?v=2023-01-07T11%3A19%3A42.762Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/c6dd4b7a1108b8ab170b04c351ffe22bbb18d97d.00000692.jpg?v=2023-01-07T11%3A19%3A42.762Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/c6dd4b7a1108b8ab170b04c351ffe22bbb18d97d.00000692.jpg?v=2023-01-07T11%3A19%3A42.762Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/c6dd4b7a1108b8ab170b04c351ffe22bbb18d97d.00000692.jpg?v=2023-01-07T11%3A19%3A42.762Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Structure height="min-content" width="fit-content">
							<Override slot="Content">
								<Override slot="cell-0">
									<Text
										margin="0px 0px 11px 0px"
										color="--darkL2"
										font="normal 500 18px/1.2 --fontFamily-googleAssistant"
										lg-text-align="left"
										quarkly-title="  ₪46.00"
										flex="0 0 auto"
										width="auto"
										text-align="center"
										sm-align-self="flex-start"
									/>
								</Override>
								<Override slot="cell-2" />
								<Override slot="Cell 0th" width="max-content" />
								<Override slot="cell-1">
									<Text
										margin="0px 0px 48px 0px"
										color="--dark"
										text-align="center"
										font="--base"
										width="max-content"
									>
										<Strong>
											מוגש עם ציפס ושתיה לבחירה{"\n\n"}
										</Strong>
									</Text>
								</Override>
							</Override>
						</Structure>
						<Button
							background="#007a12"
							quarkly-title="077"
							flex="0 0 auto"
							font="normal 300 15px/1.5 --fontFamily-googleAssistant"
							overflow-x="visible"
							overflow-y="visible"
							padding="10px 10px 10px 10px"
							margin="10px 0px 0px 10px"
							type="link"
							href="tel:0775008966"
							sm-width="max-content"
							display="flex"
							width="max-content"
							align-self="flex-start"
						>
							להזמנות חייגו{" "}
							<br />
							077-5008966
						</Button>
					</Box>
					<Box
						width="100%"
						display="flex"
						padding="48px 40px 56px 40px"
						background="--color-light url(https://uploads.quarkly.io/639068b75f45170024239f08/images/54654.jpg?v=2022-12-14T09:44:16.467Z)"
						border-radius="24px"
						align-items="center"
						justify-content="center"
						lg-padding="48px 35px 56px 35px"
						border-style="solid"
						border-color="--color-lightD2"
						box-shadow="0px 0px 10px 0px rgb(0 0 0 / 50%)"
						border-width="1px"
						flex-direction="column"
						sm-align-items="stretch"
						sm-justify-content="center"
						top="-200px"
					>
						<Text
							color="--darkL2"
							font="normal 700 24px/1.2 --fontFamily-googleAssistant"
							lg-text-align="left"
							quarkly-title="ארוחת"
							flex="0 0 auto"
							width="auto"
							margin="10px 0px 16px 0px"
							text-align="right"
							display="grid"
							align-self="flex-end"
						>
							{" "}ארוחת המבורגר כבש 300 גרם
							<br />
							<br />
							{"\n\n\n\n"}
						</Text>
						<Image
							src="https://uploads.quarkly.io/639068b75f45170024239f08/images/13914b2cbfaae2ec42128345dc2e7e5123704e24.00000678.jpg?v=2023-01-07T11:19:42.774Z"
							width="100%"
							object-fit="cover"
							height="235px"
							align-self="flex-end"
							srcSet="https://smartuploads.quarkly.io/639068b75f45170024239f08/images/13914b2cbfaae2ec42128345dc2e7e5123704e24.00000678.jpg?v=2023-01-07T11%3A19%3A42.774Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/13914b2cbfaae2ec42128345dc2e7e5123704e24.00000678.jpg?v=2023-01-07T11%3A19%3A42.774Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/13914b2cbfaae2ec42128345dc2e7e5123704e24.00000678.jpg?v=2023-01-07T11%3A19%3A42.774Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/13914b2cbfaae2ec42128345dc2e7e5123704e24.00000678.jpg?v=2023-01-07T11%3A19%3A42.774Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/13914b2cbfaae2ec42128345dc2e7e5123704e24.00000678.jpg?v=2023-01-07T11%3A19%3A42.774Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/13914b2cbfaae2ec42128345dc2e7e5123704e24.00000678.jpg?v=2023-01-07T11%3A19%3A42.774Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/13914b2cbfaae2ec42128345dc2e7e5123704e24.00000678.jpg?v=2023-01-07T11%3A19%3A42.774Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Structure height="min-content" width="fit-content">
							<Override slot="Content">
								<Override slot="cell-0">
									<Text
										margin="0px 0px 11px 0px"
										color="--darkL2"
										font="normal 500 18px/1.2 --fontFamily-googleAssistant"
										lg-text-align="left"
										quarkly-title="  ₪46.00"
										flex="0 0 auto"
										width="auto"
										text-align="center"
										sm-align-self="flex-start"
									/>
								</Override>
								<Override slot="cell-2" />
								<Override slot="Cell 0th" width="max-content" />
								<Override slot="cell-1">
									<Text
										margin="0px 0px 48px 0px"
										color="--dark"
										text-align="center"
										font="--base"
										width="max-content"
									>
										<Strong>
											מוגש עם ציפס ושתיה לבחירה{"\n\n"}
										</Strong>
									</Text>
								</Override>
							</Override>
						</Structure>
						<Button
							background="#007a12"
							quarkly-title="077"
							flex="0 0 auto"
							font="normal 300 15px/1.5 --fontFamily-googleAssistant"
							overflow-x="visible"
							overflow-y="visible"
							padding="10px 10px 10px 10px"
							margin="10px 0px 0px 10px"
							type="link"
							href="tel:0775008966"
							sm-width="max-content"
							display="flex"
							width="max-content"
							align-self="flex-start"
						>
							להזמנות חייגו{" "}
							<br />
							077-5008966
						</Button>
					</Box>
					<Box
						width="100%"
						display="flex"
						padding="48px 40px 56px 40px"
						background="--color-light url(https://uploads.quarkly.io/639068b75f45170024239f08/images/54654.jpg?v=2022-12-14T09:44:16.467Z)"
						border-radius="24px"
						align-items="center"
						justify-content="center"
						lg-padding="48px 35px 56px 35px"
						border-style="solid"
						border-color="--color-lightD2"
						box-shadow="0px 0px 10px 0px rgb(0 0 0 / 50%)"
						border-width="1px"
						flex-direction="column"
						sm-align-items="stretch"
						sm-justify-content="center"
						top="-200px"
					>
						<Text
							color="--darkL2"
							font="normal 700 24px/1.2 --fontFamily-googleAssistant"
							lg-text-align="left"
							quarkly-title="ארוחת"
							flex="0 0 auto"
							width="auto"
							margin="10px 0px 16px 0px"
							text-align="right"
							display="grid"
							align-self="flex-end"
						>
							המבורגר כבש עסיסי, 300 גרם
							<br />
							{" "}(בשר בקר ושומן בקר וכבש){"\n\n"}
						</Text>
						<Image
							src="https://uploads.quarkly.io/639068b75f45170024239f08/images/0881f46c8f574843ca8e3227bab6574298e9e86b.00000694.jpg?v=2023-01-07T11:19:42.738Z"
							width="100%"
							object-fit="cover"
							height="235px"
							align-self="flex-end"
							srcSet="https://smartuploads.quarkly.io/639068b75f45170024239f08/images/0881f46c8f574843ca8e3227bab6574298e9e86b.00000694.jpg?v=2023-01-07T11%3A19%3A42.738Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/0881f46c8f574843ca8e3227bab6574298e9e86b.00000694.jpg?v=2023-01-07T11%3A19%3A42.738Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/0881f46c8f574843ca8e3227bab6574298e9e86b.00000694.jpg?v=2023-01-07T11%3A19%3A42.738Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/0881f46c8f574843ca8e3227bab6574298e9e86b.00000694.jpg?v=2023-01-07T11%3A19%3A42.738Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/0881f46c8f574843ca8e3227bab6574298e9e86b.00000694.jpg?v=2023-01-07T11%3A19%3A42.738Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/0881f46c8f574843ca8e3227bab6574298e9e86b.00000694.jpg?v=2023-01-07T11%3A19%3A42.738Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/0881f46c8f574843ca8e3227bab6574298e9e86b.00000694.jpg?v=2023-01-07T11%3A19%3A42.738Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Structure height="min-content" width="fit-content">
							<Override slot="Content">
								<Override slot="cell-0">
									<Text
										margin="0px 0px 11px 0px"
										color="--darkL2"
										font="normal 500 18px/1.2 --fontFamily-googleAssistant"
										lg-text-align="left"
										quarkly-title="  ₪46.00"
										flex="0 0 auto"
										width="auto"
										text-align="center"
										sm-align-self="flex-start"
									/>
								</Override>
								<Override slot="cell-2" />
								<Override slot="Cell 0th" width="max-content" />
								<Override slot="cell-1">
									<Text
										margin="0px 0px 48px 0px"
										color="--dark"
										text-align="center"
										font="--base"
										width="max-content"
									>
										<Strong>
											מוגש עם ציפס ושתיה לבחירה{"\n\n"}
										</Strong>
									</Text>
								</Override>
							</Override>
						</Structure>
						<Button
							background="#007a12"
							quarkly-title="077"
							flex="0 0 auto"
							font="normal 300 15px/1.5 --fontFamily-googleAssistant"
							overflow-x="visible"
							overflow-y="visible"
							padding="10px 10px 10px 10px"
							margin="10px 0px 0px 10px"
							type="link"
							href="tel:0775008966"
							sm-width="max-content"
							display="flex"
							width="max-content"
							align-self="flex-start"
						>
							להזמנות חייגו{" "}
							<br />
							077-5008966
						</Button>
					</Box>
					<Box
						width="100%"
						display="flex"
						padding="48px 40px 56px 40px"
						background="--color-light url(https://uploads.quarkly.io/639068b75f45170024239f08/images/54654.jpg?v=2022-12-14T09:44:16.467Z)"
						border-radius="24px"
						align-items="center"
						justify-content="center"
						lg-padding="48px 35px 56px 35px"
						border-style="solid"
						border-color="--color-lightD2"
						box-shadow="0px 0px 10px 0px rgb(0 0 0 / 50%)"
						border-width="1px"
						flex-direction="column"
						sm-align-items="stretch"
						sm-justify-content="center"
						top="-200px"
					>
						<Text
							color="--darkL2"
							font="normal 700 24px/1.2 --fontFamily-googleAssistant"
							lg-text-align="left"
							quarkly-title="ארוחת"
							flex="0 0 auto"
							width="auto"
							margin="10px 0px 16px 0px"
							text-align="right"
							display="grid"
							align-self="flex-end"
						>
							ארוחת המבורגר כבש 200 גרם{" "}
							<br />
							<br />
						</Text>
						<Image
							src="https://uploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10:53:10.432Z"
							width="100%"
							object-fit="cover"
							height="235px"
							align-self="flex-end"
							srcSet="https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Structure height="min-content" width="fit-content">
							<Override slot="Content">
								<Override slot="cell-0">
									<Text
										margin="0px 0px 11px 0px"
										color="--darkL2"
										font="normal 500 18px/1.2 --fontFamily-googleAssistant"
										lg-text-align="left"
										quarkly-title="  ₪46.00"
										flex="0 0 auto"
										width="auto"
										text-align="center"
										sm-align-self="flex-start"
									/>
								</Override>
								<Override slot="cell-2" />
								<Override slot="Cell 0th" width="max-content" />
								<Override slot="cell-1">
									<Text
										margin="0px 0px 48px 0px"
										color="--dark"
										text-align="center"
										font="--base"
										width="max-content"
									>
										<Strong>
											מוגש עם ציפס ושתיה לבחירה{"\n\n"}
										</Strong>
									</Text>
								</Override>
							</Override>
						</Structure>
						<Button
							background="#007a12"
							quarkly-title="077"
							flex="0 0 auto"
							font="normal 300 15px/1.5 --fontFamily-googleAssistant"
							overflow-x="visible"
							overflow-y="visible"
							padding="10px 10px 10px 10px"
							margin="10px 0px 0px 10px"
							type="link"
							href="tel:0775008966"
							sm-width="max-content"
							display="flex"
							width="max-content"
							align-self="flex-start"
						>
							להזמנות חייגו{" "}
							<br />
							077-5008966
						</Button>
					</Box>
					<Box
						width="100%"
						display="flex"
						padding="48px 40px 56px 40px"
						background="--color-light url(https://uploads.quarkly.io/639068b75f45170024239f08/images/54654.jpg?v=2022-12-14T09:44:16.467Z)"
						border-radius="24px"
						align-items="center"
						justify-content="center"
						lg-padding="48px 35px 56px 35px"
						border-style="solid"
						border-color="--color-lightD2"
						box-shadow="0px 0px 10px 0px rgb(0 0 0 / 50%)"
						border-width="1px"
						flex-direction="column"
						sm-align-items="stretch"
						sm-justify-content="center"
						top="-200px"
					>
						<Text
							color="--darkL2"
							font="normal 700 24px/1.2 --fontFamily-googleAssistant"
							lg-text-align="left"
							quarkly-title="ארוחת"
							flex="0 0 auto"
							width="auto"
							margin="10px 0px 16px 0px"
							text-align="right"
							display="grid"
							align-self="flex-end"
						>
							ארוחת המבורגר בקר 200 גרם
							<br />
							{" "}
							<br />
						</Text>
						<Image
							src="https://uploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10:53:10.432Z"
							width="100%"
							object-fit="cover"
							height="235px"
							align-self="flex-end"
							srcSet="https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/30a414f8-512e-4f3a-94bd-8b1fd8f6bf32.jpeg?v=2022-12-07T10%3A53%3A10.432Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Structure height="min-content" width="fit-content">
							<Override slot="Content">
								<Override slot="cell-0">
									<Text
										margin="0px 0px 11px 0px"
										color="--darkL2"
										font="normal 500 18px/1.2 --fontFamily-googleAssistant"
										lg-text-align="left"
										quarkly-title="  ₪46.00"
										flex="0 0 auto"
										width="auto"
										text-align="center"
										sm-align-self="flex-start"
									>
										<Strong
											overflow-wrap="normal"
											word-break="normal"
											white-space="normal"
											text-indent="0"
											text-overflow="clip"
											hyphens="manual"
											user-select="auto"
											pointer-events="auto"
										>
											₪{" "}
										</Strong>
									</Text>
								</Override>
								<Override slot="cell-2" />
								<Override slot="Cell 0th" width="max-content" />
								<Override slot="cell-1">
									<Text
										margin="0px 0px 48px 0px"
										color="--dark"
										text-align="center"
										font="--base"
										width="max-content"
									>
										<Strong>
											מוגש עם ציפס ושתיה לבחירה{"\n\n"}
										</Strong>
									</Text>
								</Override>
							</Override>
						</Structure>
						<Button
							background="#007a12"
							quarkly-title="077"
							flex="0 0 auto"
							font="normal 300 15px/1.5 --fontFamily-googleAssistant"
							overflow-x="visible"
							overflow-y="visible"
							padding="10px 10px 10px 10px"
							margin="10px 0px 0px 10px"
							type="link"
							href="tel:0775008966"
							sm-width="max-content"
							display="flex"
							width="max-content"
							align-self="flex-start"
						>
							להזמנות חייגו{" "}
							<br />
							077-5008966
						</Button>
					</Box>
					<Box
						width="100%"
						display="flex"
						padding="48px 40px 56px 40px"
						background="--color-light url(https://uploads.quarkly.io/639068b75f45170024239f08/images/54654.jpg?v=2022-12-14T09:44:16.467Z)"
						border-radius="24px"
						align-items="center"
						justify-content="center"
						lg-padding="48px 35px 56px 35px"
						border-style="solid"
						border-color="--color-lightD2"
						box-shadow="0px 0px 10px 0px rgb(0 0 0 / 50%)"
						border-width="1px"
						flex-direction="column"
						sm-align-items="stretch"
						sm-justify-content="center"
						top="-200px"
					>
						<Text
							color="--darkL2"
							font="normal 700 24px/1.2 --fontFamily-googleAssistant"
							lg-text-align="left"
							quarkly-title="ארוחת"
							flex="0 0 auto"
							width="auto"
							margin="10px 0px 16px 0px"
							text-align="right"
							display="grid"
							align-self="flex-end"
						>
							ארוחת המבורגר כבש 300  גרם
							<br />
							<br />
							{" "}
						</Text>
						<Image
							src="https://uploads.quarkly.io/639068b75f45170024239f08/images/13914b2cbfaae2ec42128345dc2e7e5123704e24.00000678.jpg?v=2023-01-07T11:19:42.774Z"
							width="100%"
							object-fit="cover"
							height="235px"
							align-self="flex-end"
							srcSet="https://smartuploads.quarkly.io/639068b75f45170024239f08/images/13914b2cbfaae2ec42128345dc2e7e5123704e24.00000678.jpg?v=2023-01-07T11%3A19%3A42.774Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/13914b2cbfaae2ec42128345dc2e7e5123704e24.00000678.jpg?v=2023-01-07T11%3A19%3A42.774Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/13914b2cbfaae2ec42128345dc2e7e5123704e24.00000678.jpg?v=2023-01-07T11%3A19%3A42.774Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/13914b2cbfaae2ec42128345dc2e7e5123704e24.00000678.jpg?v=2023-01-07T11%3A19%3A42.774Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/13914b2cbfaae2ec42128345dc2e7e5123704e24.00000678.jpg?v=2023-01-07T11%3A19%3A42.774Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/13914b2cbfaae2ec42128345dc2e7e5123704e24.00000678.jpg?v=2023-01-07T11%3A19%3A42.774Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/13914b2cbfaae2ec42128345dc2e7e5123704e24.00000678.jpg?v=2023-01-07T11%3A19%3A42.774Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Structure height="min-content" width="fit-content">
							<Override slot="Content">
								<Override slot="cell-0">
									<Text
										margin="0px 0px 11px 0px"
										color="--darkL2"
										font="normal 500 18px/1.2 --fontFamily-googleAssistant"
										lg-text-align="left"
										quarkly-title="  ₪46.00"
										flex="0 0 auto"
										width="auto"
										text-align="center"
										sm-align-self="flex-start"
									>
										<Strong
											overflow-wrap="normal"
											word-break="normal"
											white-space="normal"
											text-indent="0"
											text-overflow="clip"
											hyphens="manual"
											user-select="auto"
											pointer-events="auto"
										>
											₪{" "}
										</Strong>
									</Text>
								</Override>
								<Override slot="cell-2" />
								<Override slot="Cell 0th" width="max-content" />
								<Override slot="cell-1">
									<Text
										margin="0px 0px 48px 0px"
										color="--dark"
										text-align="center"
										font="--base"
										width="max-content"
									>
										<Strong>
											מוגש עם ציפס ושתיה לבחירה{"\n\n"}
										</Strong>
									</Text>
								</Override>
							</Override>
						</Structure>
						<Button
							background="#007a12"
							quarkly-title="077"
							flex="0 0 auto"
							font="normal 300 15px/1.5 --fontFamily-googleAssistant"
							overflow-x="visible"
							overflow-y="visible"
							padding="10px 10px 10px 10px"
							margin="10px 0px 0px 10px"
							type="link"
							href="tel:0775008966"
							sm-width="max-content"
							display="flex"
							width="max-content"
							align-self="flex-start"
						>
							להזמנות חייגו{" "}
							<br />
							077-5008966
						</Button>
					</Box>
					<Box
						width="100%"
						display="flex"
						padding="48px 40px 56px 40px"
						background="--color-light url(https://uploads.quarkly.io/639068b75f45170024239f08/images/54654.jpg?v=2022-12-14T09:44:16.467Z)"
						border-radius="24px"
						align-items="center"
						justify-content="center"
						lg-padding="48px 35px 56px 35px"
						border-style="solid"
						border-color="--color-lightD2"
						box-shadow="0px 0px 10px 0px rgb(0 0 0 / 50%)"
						border-width="1px"
						flex-direction="column"
						sm-align-items="stretch"
						sm-justify-content="center"
						top="-200px"
					>
						<Text
							color="--darkL2"
							font="normal 700 24px/1.2 --fontFamily-googleAssistant"
							lg-text-align="left"
							quarkly-title="ארוחת"
							flex="0 0 auto"
							width="auto"
							margin="10px 0px 16px 0px"
							text-align="right"
							display="grid"
							align-self="flex-end"
						>
							ארוחת המבורגר בקר 270

גרם
							<br />
							{" "}
							<br />
						</Text>
						<Image
							src="https://uploads.quarkly.io/639068b75f45170024239f08/images/13914b2cbfaae2ec42128345dc2e7e5123704e24.00000678.jpg?v=2023-01-07T11:19:42.774Z"
							width="100%"
							object-fit="cover"
							height="235px"
							align-self="flex-end"
							srcSet="https://smartuploads.quarkly.io/639068b75f45170024239f08/images/13914b2cbfaae2ec42128345dc2e7e5123704e24.00000678.jpg?v=2023-01-07T11%3A19%3A42.774Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/13914b2cbfaae2ec42128345dc2e7e5123704e24.00000678.jpg?v=2023-01-07T11%3A19%3A42.774Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/13914b2cbfaae2ec42128345dc2e7e5123704e24.00000678.jpg?v=2023-01-07T11%3A19%3A42.774Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/13914b2cbfaae2ec42128345dc2e7e5123704e24.00000678.jpg?v=2023-01-07T11%3A19%3A42.774Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/13914b2cbfaae2ec42128345dc2e7e5123704e24.00000678.jpg?v=2023-01-07T11%3A19%3A42.774Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/13914b2cbfaae2ec42128345dc2e7e5123704e24.00000678.jpg?v=2023-01-07T11%3A19%3A42.774Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/13914b2cbfaae2ec42128345dc2e7e5123704e24.00000678.jpg?v=2023-01-07T11%3A19%3A42.774Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Structure height="min-content" width="fit-content">
							<Override slot="Content">
								<Override slot="cell-0">
									<Text
										margin="0px 0px 11px 0px"
										color="--darkL2"
										font="normal 500 18px/1.2 --fontFamily-googleAssistant"
										lg-text-align="left"
										quarkly-title="  ₪46.00"
										flex="0 0 auto"
										width="auto"
										text-align="center"
										sm-align-self="flex-start"
									/>
								</Override>
								<Override slot="cell-2" />
								<Override slot="Cell 0th" width="max-content" />
								<Override slot="cell-1">
									<Text
										margin="0px 0px 48px 0px"
										color="--dark"
										text-align="center"
										font="--base"
										width="max-content"
									>
										<Strong>
											מוגש עם ציפס ושתיה לבחירה{"\n\n"}
										</Strong>
									</Text>
								</Override>
							</Override>
						</Structure>
						<Button
							background="#007a12"
							quarkly-title="077"
							flex="0 0 auto"
							font="normal 300 15px/1.5 --fontFamily-googleAssistant"
							overflow-x="visible"
							overflow-y="visible"
							padding="10px 10px 10px 10px"
							margin="10px 0px 0px 10px"
							type="link"
							href="tel:0775008966"
							sm-width="max-content"
							display="flex"
							width="max-content"
							align-self="flex-start"
						>
							להזמנות חייגו{" "}
							<br />
							077-5008966
						</Button>
					</Box>
					<Box
						width="100%"
						display="flex"
						padding="48px 40px 56px 40px"
						background="--color-light url(https://uploads.quarkly.io/639068b75f45170024239f08/images/54654.jpg?v=2022-12-14T09:44:16.467Z)"
						border-radius="24px"
						align-items="center"
						justify-content="center"
						lg-padding="48px 35px 56px 35px"
						border-style="solid"
						border-color="--color-lightD2"
						box-shadow="0px 0px 10px 0px rgb(0 0 0 / 50%)"
						border-width="1px"
						flex-direction="column"
						sm-align-items="stretch"
						sm-justify-content="center"
						top="-200px"
					>
						<Text
							color="--darkL2"
							font="normal 700 24px/1.2 --fontFamily-googleAssistant"
							lg-text-align="left"
							quarkly-title="ארוחת"
							flex="0 0 auto"
							width="auto"
							margin="10px 0px 16px 0px"
							text-align="right"
							display="grid"
							align-self="flex-end"
						>
							ארוחת דאבל חזה עוף
							<br />
							<br />
						</Text>
						<Image
							src="https://uploads.quarkly.io/639068b75f45170024239f08/images/ee4671df594a3c7352ee9809bee9f897a44e4c42.00000775.jpg?v=2023-01-07T11:19:42.760Z"
							width="100%"
							object-fit="cover"
							height="235px"
							align-self="flex-end"
							srcSet="https://smartuploads.quarkly.io/639068b75f45170024239f08/images/ee4671df594a3c7352ee9809bee9f897a44e4c42.00000775.jpg?v=2023-01-07T11%3A19%3A42.760Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/ee4671df594a3c7352ee9809bee9f897a44e4c42.00000775.jpg?v=2023-01-07T11%3A19%3A42.760Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/ee4671df594a3c7352ee9809bee9f897a44e4c42.00000775.jpg?v=2023-01-07T11%3A19%3A42.760Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/ee4671df594a3c7352ee9809bee9f897a44e4c42.00000775.jpg?v=2023-01-07T11%3A19%3A42.760Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/ee4671df594a3c7352ee9809bee9f897a44e4c42.00000775.jpg?v=2023-01-07T11%3A19%3A42.760Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/ee4671df594a3c7352ee9809bee9f897a44e4c42.00000775.jpg?v=2023-01-07T11%3A19%3A42.760Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/ee4671df594a3c7352ee9809bee9f897a44e4c42.00000775.jpg?v=2023-01-07T11%3A19%3A42.760Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Structure height="min-content" width="fit-content">
							<Override slot="Content">
								<Override slot="cell-0">
									<Text
										margin="0px 0px 11px 0px"
										color="--darkL2"
										font="normal 500 18px/1.2 --fontFamily-googleAssistant"
										lg-text-align="left"
										quarkly-title="  ₪46.00"
										flex="0 0 auto"
										width="auto"
										text-align="center"
										sm-align-self="flex-start"
									/>
								</Override>
								<Override slot="cell-2" />
								<Override slot="Cell 0th" width="max-content" />
								<Override slot="cell-1">
									<Text
										margin="0px 0px 48px 0px"
										color="--dark"
										text-align="center"
										font="--base"
										width="max-content"
									>
										<Strong>
											מוגש עם ציפס ושתיה לבחירה{"\n\n"}
										</Strong>
									</Text>
								</Override>
							</Override>
						</Structure>
						<Button
							background="#007a12"
							quarkly-title="077"
							flex="0 0 auto"
							font="normal 300 15px/1.5 --fontFamily-googleAssistant"
							overflow-x="visible"
							overflow-y="visible"
							padding="10px 10px 10px 10px"
							margin="10px 0px 0px 10px"
							type="link"
							href="tel:0775008966"
							sm-width="max-content"
							display="flex"
							width="max-content"
							align-self="flex-start"
						>
							להזמנות חייגו{" "}
							<br />
							077-5008966
						</Button>
					</Box>
					<Box
						width="100%"
						display="flex"
						padding="48px 40px 56px 40px"
						background="--color-light url(https://uploads.quarkly.io/639068b75f45170024239f08/images/54654.jpg?v=2022-12-14T09:44:16.467Z)"
						border-radius="24px"
						align-items="center"
						justify-content="center"
						lg-padding="48px 35px 56px 35px"
						border-style="solid"
						border-color="--color-lightD2"
						box-shadow="0px 0px 10px 0px rgb(0 0 0 / 50%)"
						border-width="1px"
						flex-direction="column"
						sm-align-items="stretch"
						sm-justify-content="center"
						top="-200px"
					>
						<Text
							color="--darkL2"
							font="normal 700 24px/1.2 --fontFamily-googleAssistant"
							lg-text-align="left"
							quarkly-title="ארוחת"
							flex="0 0 auto"
							width="auto"
							margin="10px 0px 16px 0px"
							text-align="right"
							display="grid"
							align-self="flex-end"
						>
							ארוחת חזה עוף
							<br />
							<br />
						</Text>
						<Image
							src="https://uploads.quarkly.io/639068b75f45170024239f08/images/3e797622fd9fc6cac13e99c046b5131113d4efa0.00000800.jpg?v=2023-01-07T11:19:42.771Z"
							width="100%"
							object-fit="cover"
							height="235px"
							align-self="flex-end"
							srcSet="https://smartuploads.quarkly.io/639068b75f45170024239f08/images/3e797622fd9fc6cac13e99c046b5131113d4efa0.00000800.jpg?v=2023-01-07T11%3A19%3A42.771Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/3e797622fd9fc6cac13e99c046b5131113d4efa0.00000800.jpg?v=2023-01-07T11%3A19%3A42.771Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/3e797622fd9fc6cac13e99c046b5131113d4efa0.00000800.jpg?v=2023-01-07T11%3A19%3A42.771Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/3e797622fd9fc6cac13e99c046b5131113d4efa0.00000800.jpg?v=2023-01-07T11%3A19%3A42.771Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/3e797622fd9fc6cac13e99c046b5131113d4efa0.00000800.jpg?v=2023-01-07T11%3A19%3A42.771Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/3e797622fd9fc6cac13e99c046b5131113d4efa0.00000800.jpg?v=2023-01-07T11%3A19%3A42.771Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/3e797622fd9fc6cac13e99c046b5131113d4efa0.00000800.jpg?v=2023-01-07T11%3A19%3A42.771Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Structure height="min-content" width="fit-content">
							<Override slot="Content">
								<Override slot="cell-0">
									<Text
										margin="0px 0px 11px 0px"
										color="--darkL2"
										font="normal 500 18px/1.2 --fontFamily-googleAssistant"
										lg-text-align="left"
										quarkly-title="  ₪46.00"
										flex="0 0 auto"
										width="auto"
										text-align="center"
										sm-align-self="flex-start"
									/>
								</Override>
								<Override slot="cell-2" />
								<Override slot="Cell 0th" width="max-content" />
								<Override slot="cell-1">
									<Text
										margin="0px 0px 48px 0px"
										color="--dark"
										text-align="center"
										font="--base"
										width="max-content"
									>
										<Strong>
											מוגש עם ציפס ושתיה לבחירה{"\n\n"}
										</Strong>
									</Text>
								</Override>
							</Override>
						</Structure>
						<Button
							background="#007a12"
							quarkly-title="077"
							flex="0 0 auto"
							font="normal 300 15px/1.5 --fontFamily-googleAssistant"
							overflow-x="visible"
							overflow-y="visible"
							padding="10px 10px 10px 10px"
							margin="10px 0px 0px 10px"
							type="link"
							href="tel:0775008966"
							sm-width="max-content"
							display="flex"
							width="max-content"
							align-self="flex-start"
						>
							להזמנות חייגו{" "}
							<br />
							077-5008966
						</Button>
					</Box>
					<Box
						width="100%"
						display="flex"
						padding="48px 40px 56px 40px"
						background="--color-light url(https://uploads.quarkly.io/639068b75f45170024239f08/images/54654.jpg?v=2022-12-14T09:44:16.467Z)"
						border-radius="24px"
						align-items="center"
						justify-content="center"
						lg-padding="48px 35px 56px 35px"
						border-style="solid"
						border-color="--color-lightD2"
						box-shadow="0px 0px 10px 0px rgb(0 0 0 / 50%)"
						border-width="1px"
						flex-direction="column"
						sm-align-items="stretch"
						sm-justify-content="center"
						top="-200px"
					>
						<Text
							color="--darkL2"
							font="normal 700 24px/1.2 --fontFamily-googleAssistant"
							lg-text-align="left"
							quarkly-title="ארוחת"
							flex="0 0 auto"
							width="auto"
							margin="10px 0px 16px 0px"
							text-align="right"
							display="grid"
							align-self="flex-end"
						>
							ארוחת המבורגר טבעוני ביונד
							<br />
							<br />
						</Text>
						<Image
							src="https://uploads.quarkly.io/639068b75f45170024239f08/images/e73a4b52d31aa512979f1e42b271860aaadd037a.00000713.png?v=2023-01-07T11:19:42.760Z"
							width="100%"
							object-fit="cover"
							height="235px"
							align-self="flex-end"
							srcSet="https://smartuploads.quarkly.io/639068b75f45170024239f08/images/e73a4b52d31aa512979f1e42b271860aaadd037a.00000713.png?v=2023-01-07T11%3A19%3A42.760Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/e73a4b52d31aa512979f1e42b271860aaadd037a.00000713.png?v=2023-01-07T11%3A19%3A42.760Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/e73a4b52d31aa512979f1e42b271860aaadd037a.00000713.png?v=2023-01-07T11%3A19%3A42.760Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/e73a4b52d31aa512979f1e42b271860aaadd037a.00000713.png?v=2023-01-07T11%3A19%3A42.760Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/e73a4b52d31aa512979f1e42b271860aaadd037a.00000713.png?v=2023-01-07T11%3A19%3A42.760Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/e73a4b52d31aa512979f1e42b271860aaadd037a.00000713.png?v=2023-01-07T11%3A19%3A42.760Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/e73a4b52d31aa512979f1e42b271860aaadd037a.00000713.png?v=2023-01-07T11%3A19%3A42.760Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Structure height="min-content" width="fit-content">
							<Override slot="Content">
								<Override slot="cell-0">
									<Text
										margin="0px 0px 11px 0px"
										color="--darkL2"
										font="normal 500 18px/1.2 --fontFamily-googleAssistant"
										lg-text-align="left"
										quarkly-title="  ₪46.00"
										flex="0 0 auto"
										width="auto"
										text-align="center"
										sm-align-self="flex-start"
									/>
								</Override>
								<Override slot="cell-2" />
								<Override slot="Cell 0th" width="max-content" />
								<Override slot="cell-1">
									<Text
										margin="0px 0px 48px 0px"
										color="--dark"
										text-align="center"
										font="--base"
										width="max-content"
									>
										<Strong>
											מוגש עם ציפס ושתיה לבחירה{"\n\n"}
										</Strong>
									</Text>
								</Override>
							</Override>
						</Structure>
						<Button
							background="#007a12"
							quarkly-title="077"
							flex="0 0 auto"
							font="normal 300 15px/1.5 --fontFamily-googleAssistant"
							overflow-x="visible"
							overflow-y="visible"
							padding="10px 10px 10px 10px"
							margin="10px 0px 0px 10px"
							type="link"
							href="tel:0775008966"
							sm-width="max-content"
							display="flex"
							width="max-content"
							align-self="flex-start"
						>
							להזמנות חייגו{" "}
							<br />
							077-5008966
						</Button>
					</Box>
					<Box
						width="100%"
						display="flex"
						padding="48px 40px 56px 40px"
						background="--color-light url(https://uploads.quarkly.io/639068b75f45170024239f08/images/54654.jpg?v=2022-12-14T09:44:16.467Z)"
						border-radius="24px"
						align-items="center"
						justify-content="center"
						lg-padding="48px 35px 56px 35px"
						border-style="solid"
						border-color="--color-lightD2"
						box-shadow="0px 0px 10px 0px rgb(0 0 0 / 50%)"
						border-width="1px"
						flex-direction="column"
						sm-align-items="stretch"
						sm-justify-content="center"
						top="-200px"
					>
						<Text
							color="--darkL2"
							font="normal 700 24px/1.2 --fontFamily-googleAssistant"
							lg-text-align="left"
							quarkly-title="ארוחת"
							flex="0 0 auto"
							width="auto"
							margin="10px 0px 16px 0px"
							text-align="right"
							display="grid"
							align-self="flex-end"
						>
							ארוחת שניצל
							<br />
							<br />
						</Text>
						<Image
							src="https://uploads.quarkly.io/639068b75f45170024239f08/images/ee4671df594a3c7352ee9809bee9f897a44e4c42.00000775.jpg?v=2023-01-07T11:19:42.760Z"
							width="100%"
							object-fit="cover"
							height="235px"
							align-self="flex-end"
							srcSet="https://smartuploads.quarkly.io/639068b75f45170024239f08/images/ee4671df594a3c7352ee9809bee9f897a44e4c42.00000775.jpg?v=2023-01-07T11%3A19%3A42.760Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/ee4671df594a3c7352ee9809bee9f897a44e4c42.00000775.jpg?v=2023-01-07T11%3A19%3A42.760Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/ee4671df594a3c7352ee9809bee9f897a44e4c42.00000775.jpg?v=2023-01-07T11%3A19%3A42.760Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/ee4671df594a3c7352ee9809bee9f897a44e4c42.00000775.jpg?v=2023-01-07T11%3A19%3A42.760Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/ee4671df594a3c7352ee9809bee9f897a44e4c42.00000775.jpg?v=2023-01-07T11%3A19%3A42.760Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/ee4671df594a3c7352ee9809bee9f897a44e4c42.00000775.jpg?v=2023-01-07T11%3A19%3A42.760Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/ee4671df594a3c7352ee9809bee9f897a44e4c42.00000775.jpg?v=2023-01-07T11%3A19%3A42.760Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Structure height="min-content" width="fit-content">
							<Override slot="Content">
								<Override slot="cell-0">
									<Text
										margin="0px 0px 11px 0px"
										color="--darkL2"
										font="normal 500 18px/1.2 --fontFamily-googleAssistant"
										lg-text-align="left"
										quarkly-title="  ₪46.00"
										flex="0 0 auto"
										width="auto"
										text-align="center"
										sm-align-self="flex-start"
									/>
								</Override>
								<Override slot="cell-2" />
								<Override slot="Cell 0th" width="max-content" />
								<Override slot="cell-1">
									<Text
										margin="0px 0px 48px 0px"
										color="--dark"
										text-align="center"
										font="--base"
										width="max-content"
									>
										<Strong>
											מוגש עם ציפס ושתיה לבחירה{"\n\n"}
										</Strong>
									</Text>
								</Override>
							</Override>
						</Structure>
						<Button
							background="#007a12"
							quarkly-title="077"
							flex="0 0 auto"
							font="normal 300 15px/1.5 --fontFamily-googleAssistant"
							overflow-x="visible"
							overflow-y="visible"
							padding="10px 10px 10px 10px"
							margin="10px 0px 0px 10px"
							type="link"
							href="tel:0775008966"
							sm-width="max-content"
							display="flex"
							width="max-content"
							align-self="flex-start"
						>
							להזמנות חייגו{" "}
							<br />
							077-5008966
						</Button>
					</Box>
					<Box
						width="100%"
						display="flex"
						padding="48px 40px 56px 40px"
						background="--color-light url(https://uploads.quarkly.io/639068b75f45170024239f08/images/54654.jpg?v=2022-12-14T09:44:16.467Z)"
						border-radius="24px"
						align-items="center"
						justify-content="center"
						lg-padding="48px 35px 56px 35px"
						border-style="solid"
						border-color="--color-lightD2"
						box-shadow="0px 0px 10px 0px rgb(0 0 0 / 50%)"
						border-width="1px"
						flex-direction="column"
						sm-align-items="stretch"
						sm-justify-content="center"
						top="-200px"
					>
						<Text
							color="--darkL2"
							font="normal 700 24px/1.2 --fontFamily-googleAssistant"
							lg-text-align="left"
							quarkly-title="ארוחת"
							flex="0 0 auto"
							width="auto"
							margin="10px 0px 16px 0px"
							text-align="right"
							display="grid"
							align-self="flex-end"
						>
							המבורגר כבש עסיסי, 200 גרם
							<br />
							{" "}(בשר בקר ושומן בקר וכבש)
						</Text>
						<Image
							src="https://uploads.quarkly.io/639068b75f45170024239f08/images/c6dd4b7a1108b8ab170b04c351ffe22bbb18d97d.00000692.jpg?v=2023-01-07T11:19:42.762Z"
							width="100%"
							object-fit="cover"
							height="235px"
							align-self="flex-end"
							srcSet="https://smartuploads.quarkly.io/639068b75f45170024239f08/images/c6dd4b7a1108b8ab170b04c351ffe22bbb18d97d.00000692.jpg?v=2023-01-07T11%3A19%3A42.762Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/c6dd4b7a1108b8ab170b04c351ffe22bbb18d97d.00000692.jpg?v=2023-01-07T11%3A19%3A42.762Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/c6dd4b7a1108b8ab170b04c351ffe22bbb18d97d.00000692.jpg?v=2023-01-07T11%3A19%3A42.762Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/c6dd4b7a1108b8ab170b04c351ffe22bbb18d97d.00000692.jpg?v=2023-01-07T11%3A19%3A42.762Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/c6dd4b7a1108b8ab170b04c351ffe22bbb18d97d.00000692.jpg?v=2023-01-07T11%3A19%3A42.762Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/c6dd4b7a1108b8ab170b04c351ffe22bbb18d97d.00000692.jpg?v=2023-01-07T11%3A19%3A42.762Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/c6dd4b7a1108b8ab170b04c351ffe22bbb18d97d.00000692.jpg?v=2023-01-07T11%3A19%3A42.762Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Structure height="min-content" width="fit-content">
							<Override slot="Content">
								<Override slot="cell-0">
									<Text
										margin="0px 0px 11px 0px"
										color="--darkL2"
										font="normal 500 18px/1.2 --fontFamily-googleAssistant"
										lg-text-align="left"
										quarkly-title="  ₪46.00"
										flex="0 0 auto"
										width="auto"
										text-align="center"
										sm-align-self="flex-start"
									/>
								</Override>
								<Override slot="cell-2" />
								<Override slot="Cell 0th" width="max-content" />
								<Override slot="cell-1">
									<Text
										margin="0px 0px 48px 0px"
										color="--dark"
										text-align="center"
										font="--base"
										width="max-content"
									>
										<Strong>
											מוגש עם ציפס ושתיה לבחירה{"\n\n"}
										</Strong>
									</Text>
								</Override>
							</Override>
						</Structure>
						<Button
							background="#007a12"
							quarkly-title="077"
							flex="0 0 auto"
							font="normal 300 15px/1.5 --fontFamily-googleAssistant"
							overflow-x="visible"
							overflow-y="visible"
							padding="10px 10px 10px 10px"
							margin="10px 0px 0px 10px"
							type="link"
							href="tel:0775008966"
							sm-width="max-content"
							display="flex"
							width="max-content"
							align-self="flex-start"
						>
							להזמנות חייגו{" "}
							<br />
							077-5008966
						</Button>
					</Box>
					<Box
						width="100%"
						display="flex"
						padding="48px 40px 56px 40px"
						background="--color-light url(https://uploads.quarkly.io/639068b75f45170024239f08/images/54654.jpg?v=2022-12-14T09:44:16.467Z)"
						border-radius="24px"
						align-items="center"
						justify-content="center"
						lg-padding="48px 35px 56px 35px"
						border-style="solid"
						border-color="--color-lightD2"
						box-shadow="0px 0px 10px 0px rgb(0 0 0 / 50%)"
						border-width="1px"
						flex-direction="column"
						sm-align-items="stretch"
						sm-justify-content="center"
						top="-200px"
					>
						<Text
							color="--darkL2"
							font="normal 700 24px/1.2 --fontFamily-googleAssistant"
							lg-text-align="left"
							quarkly-title="ארוחת"
							flex="0 0 auto"
							width="auto"
							margin="10px 0px 16px 0px"
							text-align="right"
							display="grid"
							align-self="flex-end"
						>
							המבורגר 200 גרם עשוי מבשר בקר משובח
							<br />
							<br />
						</Text>
						<Image
							src="https://uploads.quarkly.io/639068b75f45170024239f08/images/fbf698559b6cff71ef9c1651a0cb11380607474e.00000740.jpg?v=2023-01-07T11:19:42.765Z"
							width="100%"
							object-fit="cover"
							height="235px"
							align-self="flex-end"
							srcSet="https://smartuploads.quarkly.io/639068b75f45170024239f08/images/fbf698559b6cff71ef9c1651a0cb11380607474e.00000740.jpg?v=2023-01-07T11%3A19%3A42.765Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/fbf698559b6cff71ef9c1651a0cb11380607474e.00000740.jpg?v=2023-01-07T11%3A19%3A42.765Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/fbf698559b6cff71ef9c1651a0cb11380607474e.00000740.jpg?v=2023-01-07T11%3A19%3A42.765Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/fbf698559b6cff71ef9c1651a0cb11380607474e.00000740.jpg?v=2023-01-07T11%3A19%3A42.765Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/fbf698559b6cff71ef9c1651a0cb11380607474e.00000740.jpg?v=2023-01-07T11%3A19%3A42.765Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/fbf698559b6cff71ef9c1651a0cb11380607474e.00000740.jpg?v=2023-01-07T11%3A19%3A42.765Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/fbf698559b6cff71ef9c1651a0cb11380607474e.00000740.jpg?v=2023-01-07T11%3A19%3A42.765Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Structure height="min-content" width="fit-content">
							<Override slot="Content">
								<Override slot="cell-0">
									<Text
										margin="0px 0px 11px 0px"
										color="--darkL2"
										font="normal 500 18px/1.2 --fontFamily-googleAssistant"
										lg-text-align="left"
										quarkly-title="  ₪46.00"
										flex="0 0 auto"
										width="auto"
										text-align="center"
										sm-align-self="flex-start"
									/>
								</Override>
								<Override slot="cell-2" />
								<Override slot="Cell 0th" width="max-content" />
								<Override slot="cell-1">
									<Text
										margin="0px 0px 48px 0px"
										color="--dark"
										text-align="center"
										font="--base"
										width="max-content"
									>
										<Strong>
											מוגש עם ציפס ושתיה לבחירה{"\n\n"}
										</Strong>
									</Text>
								</Override>
							</Override>
						</Structure>
						<Button
							background="#007a12"
							quarkly-title="077"
							flex="0 0 auto"
							font="normal 300 15px/1.5 --fontFamily-googleAssistant"
							overflow-x="visible"
							overflow-y="visible"
							padding="10px 10px 10px 10px"
							margin="10px 0px 0px 10px"
							type="link"
							href="tel:0775008966"
							sm-width="max-content"
							display="flex"
							width="max-content"
							align-self="flex-start"
						>
							להזמנות חייגו{" "}
							<br />
							077-5008966
						</Button>
					</Box>
					<Box
						width="100%"
						display="flex"
						padding="48px 40px 56px 40px"
						background="--color-light url(https://uploads.quarkly.io/639068b75f45170024239f08/images/54654.jpg?v=2022-12-14T09:44:16.467Z)"
						border-radius="24px"
						align-items="center"
						justify-content="center"
						lg-padding="48px 35px 56px 35px"
						border-style="solid"
						border-color="--color-lightD2"
						box-shadow="0px 0px 10px 0px rgb(0 0 0 / 50%)"
						border-width="1px"
						flex-direction="column"
						sm-align-items="stretch"
						sm-justify-content="center"
						top="-200px"
					>
						<Text
							color="--darkL2"
							font="normal 700 24px/1.2 --fontFamily-googleAssistant"
							lg-text-align="left"
							quarkly-title="ארוחת"
							flex="0 0 auto"
							width="auto"
							margin="10px 0px 16px 0px"
							text-align="right"
							display="grid"
							align-self="flex-end"
						>
							המבורגר כבש עסיסי (דאבל בלחמניה)
							<br />
							{" "}400 גרם
						</Text>
						<Image
							src="https://uploads.quarkly.io/639068b75f45170024239f08/images/d6767f8f91fe1225234536e8b4192f2b3f695d03.00000690.jpg?v=2023-01-07T11:19:42.752Z"
							width="100%"
							object-fit="cover"
							height="235px"
							align-self="flex-end"
							srcSet="https://smartuploads.quarkly.io/639068b75f45170024239f08/images/d6767f8f91fe1225234536e8b4192f2b3f695d03.00000690.jpg?v=2023-01-07T11%3A19%3A42.752Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/d6767f8f91fe1225234536e8b4192f2b3f695d03.00000690.jpg?v=2023-01-07T11%3A19%3A42.752Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/d6767f8f91fe1225234536e8b4192f2b3f695d03.00000690.jpg?v=2023-01-07T11%3A19%3A42.752Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/d6767f8f91fe1225234536e8b4192f2b3f695d03.00000690.jpg?v=2023-01-07T11%3A19%3A42.752Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/d6767f8f91fe1225234536e8b4192f2b3f695d03.00000690.jpg?v=2023-01-07T11%3A19%3A42.752Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/d6767f8f91fe1225234536e8b4192f2b3f695d03.00000690.jpg?v=2023-01-07T11%3A19%3A42.752Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/d6767f8f91fe1225234536e8b4192f2b3f695d03.00000690.jpg?v=2023-01-07T11%3A19%3A42.752Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Structure height="min-content" width="fit-content">
							<Override slot="Content">
								<Override slot="cell-0">
									<Text
										margin="0px 0px 11px 0px"
										color="--darkL2"
										font="normal 500 18px/1.2 --fontFamily-googleAssistant"
										lg-text-align="left"
										quarkly-title="  ₪46.00"
										flex="0 0 auto"
										width="auto"
										text-align="center"
										sm-align-self="flex-start"
									/>
								</Override>
								<Override slot="cell-2" />
								<Override slot="Cell 0th" width="max-content" />
								<Override slot="cell-1">
									<Text
										margin="0px 0px 48px 0px"
										color="--dark"
										text-align="center"
										font="--base"
										width="max-content"
									>
										<Strong>
											מוגש עם ציפס ושתיה לבחירה{"\n\n"}
										</Strong>
									</Text>
								</Override>
							</Override>
						</Structure>
						<Button
							background="#007a12"
							quarkly-title="077"
							flex="0 0 auto"
							font="normal 300 15px/1.5 --fontFamily-googleAssistant"
							overflow-x="visible"
							overflow-y="visible"
							padding="10px 10px 10px 10px"
							margin="10px 0px 0px 10px"
							type="link"
							href="tel:0775008966"
							sm-width="max-content"
							display="flex"
							width="max-content"
							align-self="flex-start"
						>
							להזמנות חייגו{" "}
							<br />
							077-5008966
						</Button>
					</Box>
					<Box
						width="100%"
						display="flex"
						padding="48px 40px 56px 40px"
						background="--color-light url(https://uploads.quarkly.io/639068b75f45170024239f08/images/54654.jpg?v=2022-12-14T09:44:16.467Z)"
						border-radius="24px"
						align-items="center"
						justify-content="center"
						lg-padding="48px 35px 56px 35px"
						border-style="solid"
						border-color="--color-lightD2"
						box-shadow="0px 0px 10px 0px rgb(0 0 0 / 50%)"
						border-width="1px"
						flex-direction="column"
						sm-align-items="stretch"
						sm-justify-content="center"
						top="-200px"
					>
						<Text
							color="--darkL2"
							font="normal 700 24px/1.2 --fontFamily-googleAssistant"
							lg-text-align="left"
							quarkly-title="ארוחת"
							flex="0 0 auto"
							width="auto"
							margin="10px 0px 16px 0px"
							text-align="right"
							display="grid"
							align-self="flex-end"
						>
							המבורגר בקר משובח (דאבל בלחמניה)
							<br />
							{" "}400 גרם
						</Text>
						<Image
							src="https://uploads.quarkly.io/639068b75f45170024239f08/images/d6767f8f91fe1225234536e8b4192f2b3f695d03.00000690.jpg?v=2023-01-07T11:19:42.752Z"
							width="100%"
							object-fit="cover"
							height="235px"
							align-self="flex-end"
							srcSet="https://smartuploads.quarkly.io/639068b75f45170024239f08/images/d6767f8f91fe1225234536e8b4192f2b3f695d03.00000690.jpg?v=2023-01-07T11%3A19%3A42.752Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/d6767f8f91fe1225234536e8b4192f2b3f695d03.00000690.jpg?v=2023-01-07T11%3A19%3A42.752Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/d6767f8f91fe1225234536e8b4192f2b3f695d03.00000690.jpg?v=2023-01-07T11%3A19%3A42.752Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/d6767f8f91fe1225234536e8b4192f2b3f695d03.00000690.jpg?v=2023-01-07T11%3A19%3A42.752Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/d6767f8f91fe1225234536e8b4192f2b3f695d03.00000690.jpg?v=2023-01-07T11%3A19%3A42.752Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/d6767f8f91fe1225234536e8b4192f2b3f695d03.00000690.jpg?v=2023-01-07T11%3A19%3A42.752Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/d6767f8f91fe1225234536e8b4192f2b3f695d03.00000690.jpg?v=2023-01-07T11%3A19%3A42.752Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Structure height="min-content" width="fit-content">
							<Override slot="Content">
								<Override slot="cell-0">
									<Text
										margin="0px 0px 11px 0px"
										color="--darkL2"
										font="normal 500 18px/1.2 --fontFamily-googleAssistant"
										lg-text-align="left"
										quarkly-title="  ₪46.00"
										flex="0 0 auto"
										width="auto"
										text-align="center"
										sm-align-self="flex-start"
									/>
								</Override>
								<Override slot="cell-2" />
								<Override slot="Cell 0th" width="max-content" />
								<Override slot="cell-1">
									<Text
										margin="0px 0px 48px 0px"
										color="--dark"
										text-align="center"
										font="--base"
										width="max-content"
									>
										<Strong>
											מוגש עם ציפס ושתיה לבחירה{"\n\n"}
										</Strong>
									</Text>
								</Override>
							</Override>
						</Structure>
						<Button
							background="#007a12"
							quarkly-title="077"
							flex="0 0 auto"
							font="normal 300 15px/1.5 --fontFamily-googleAssistant"
							overflow-x="visible"
							overflow-y="visible"
							padding="10px 10px 10px 10px"
							margin="10px 0px 0px 10px"
							type="link"
							href="tel:0775008966"
							sm-width="max-content"
							display="flex"
							width="max-content"
							align-self="flex-start"
						>
							להזמנות חייגו{" "}
							<br />
							077-5008966
						</Button>
					</Box>
					<Box
						width="100%"
						display="flex"
						padding="48px 40px 56px 40px"
						background="--color-light url(https://uploads.quarkly.io/639068b75f45170024239f08/images/54654.jpg?v=2022-12-14T09:44:16.467Z)"
						border-radius="24px"
						align-items="center"
						justify-content="center"
						lg-padding="48px 35px 56px 35px"
						border-style="solid"
						border-color="--color-lightD2"
						box-shadow="0px 0px 10px 0px rgb(0 0 0 / 50%)"
						border-width="1px"
						flex-direction="column"
						sm-align-items="stretch"
						sm-justify-content="center"
						top="-200px"
					>
						<Text
							color="--darkL2"
							font="normal 700 24px/1.2 --fontFamily-googleAssistant"
							lg-text-align="left"
							quarkly-title="ארוחת"
							flex="0 0 auto"
							width="auto"
							margin="10px 0px 16px 0px"
							text-align="right"
							display="grid"
							align-self="flex-end"
						>
							המבורגר כבש עסיסי 300 גרם
							<br />
							{" "}(בשר בקר ושומן בקר וכבש)
						</Text>
						<Image
							src="https://uploads.quarkly.io/639068b75f45170024239f08/images/d6767f8f91fe1225234536e8b4192f2b3f695d03.00000690.jpg?v=2023-01-07T11:19:42.752Z"
							width="100%"
							object-fit="cover"
							height="235px"
							align-self="flex-end"
							srcSet="https://smartuploads.quarkly.io/639068b75f45170024239f08/images/d6767f8f91fe1225234536e8b4192f2b3f695d03.00000690.jpg?v=2023-01-07T11%3A19%3A42.752Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/d6767f8f91fe1225234536e8b4192f2b3f695d03.00000690.jpg?v=2023-01-07T11%3A19%3A42.752Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/d6767f8f91fe1225234536e8b4192f2b3f695d03.00000690.jpg?v=2023-01-07T11%3A19%3A42.752Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/d6767f8f91fe1225234536e8b4192f2b3f695d03.00000690.jpg?v=2023-01-07T11%3A19%3A42.752Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/d6767f8f91fe1225234536e8b4192f2b3f695d03.00000690.jpg?v=2023-01-07T11%3A19%3A42.752Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/d6767f8f91fe1225234536e8b4192f2b3f695d03.00000690.jpg?v=2023-01-07T11%3A19%3A42.752Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/d6767f8f91fe1225234536e8b4192f2b3f695d03.00000690.jpg?v=2023-01-07T11%3A19%3A42.752Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Structure height="min-content" width="fit-content">
							<Override slot="Content">
								<Override slot="cell-0">
									<Text
										margin="0px 0px 11px 0px"
										color="--darkL2"
										font="normal 500 18px/1.2 --fontFamily-googleAssistant"
										lg-text-align="left"
										quarkly-title="  ₪46.00"
										flex="0 0 auto"
										width="auto"
										text-align="center"
										sm-align-self="flex-start"
									/>
								</Override>
								<Override slot="cell-2" />
								<Override slot="Cell 0th" width="max-content" />
								<Override slot="cell-1">
									<Text
										margin="0px 0px 48px 0px"
										color="--dark"
										text-align="center"
										font="--base"
										width="max-content"
									>
										<Strong>
											מוגש עם ציפס ושתיה לבחירה{"\n\n"}
										</Strong>
									</Text>
								</Override>
							</Override>
						</Structure>
						<Button
							background="#007a12"
							quarkly-title="077"
							flex="0 0 auto"
							font="normal 300 15px/1.5 --fontFamily-googleAssistant"
							overflow-x="visible"
							overflow-y="visible"
							padding="10px 10px 10px 10px"
							margin="10px 0px 0px 10px"
							type="link"
							href="tel:0775008966"
							sm-width="max-content"
							display="flex"
							width="max-content"
							align-self="flex-start"
						>
							להזמנות חייגו{" "}
							<br />
							077-5008966
						</Button>
					</Box>
					<Box
						width="100%"
						display="flex"
						padding="48px 40px 56px 40px"
						background="--color-light url(https://uploads.quarkly.io/639068b75f45170024239f08/images/54654.jpg?v=2022-12-14T09:44:16.467Z)"
						border-radius="24px"
						align-items="center"
						justify-content="center"
						lg-padding="48px 35px 56px 35px"
						border-style="solid"
						border-color="--color-lightD2"
						box-shadow="0px 0px 10px 0px rgb(0 0 0 / 50%)"
						border-width="1px"
						flex-direction="column"
						sm-align-items="stretch"
						sm-justify-content="center"
						top="-200px"
					>
						<Text
							color="--darkL2"
							font="normal 700 24px/1.2 --fontFamily-googleAssistant"
							lg-text-align="left"
							quarkly-title="ארוחת"
							flex="0 0 auto"
							width="auto"
							margin="10px 0px 16px 0px"
							text-align="right"
							display="grid"
							align-self="flex-end"
						>
							המבורגר 270 גרם עשוי מבשר{" "}
							<br />
							בקר משובח
						</Text>
						<Image
							src="https://uploads.quarkly.io/639068b75f45170024239f08/images/dc364fa9b11f5e1e0bd1f313a9b2871641c4ed19.00000741.jpg?v=2023-01-07T11:19:42.757Z"
							width="100%"
							object-fit="cover"
							height="235px"
							align-self="flex-end"
							srcSet="https://smartuploads.quarkly.io/639068b75f45170024239f08/images/dc364fa9b11f5e1e0bd1f313a9b2871641c4ed19.00000741.jpg?v=2023-01-07T11%3A19%3A42.757Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/dc364fa9b11f5e1e0bd1f313a9b2871641c4ed19.00000741.jpg?v=2023-01-07T11%3A19%3A42.757Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/dc364fa9b11f5e1e0bd1f313a9b2871641c4ed19.00000741.jpg?v=2023-01-07T11%3A19%3A42.757Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/dc364fa9b11f5e1e0bd1f313a9b2871641c4ed19.00000741.jpg?v=2023-01-07T11%3A19%3A42.757Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/dc364fa9b11f5e1e0bd1f313a9b2871641c4ed19.00000741.jpg?v=2023-01-07T11%3A19%3A42.757Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/dc364fa9b11f5e1e0bd1f313a9b2871641c4ed19.00000741.jpg?v=2023-01-07T11%3A19%3A42.757Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/639068b75f45170024239f08/images/dc364fa9b11f5e1e0bd1f313a9b2871641c4ed19.00000741.jpg?v=2023-01-07T11%3A19%3A42.757Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Structure height="min-content" width="fit-content">
							<Override slot="Content">
								<Override slot="cell-0">
									<Text
										margin="0px 0px 11px 0px"
										color="--darkL2"
										font="normal 500 18px/1.2 --fontFamily-googleAssistant"
										lg-text-align="left"
										quarkly-title="  ₪46.00"
										flex="0 0 auto"
										width="auto"
										text-align="center"
										sm-align-self="flex-start"
									/>
								</Override>
								<Override slot="cell-2" />
								<Override slot="Cell 0th" width="max-content" />
								<Override slot="cell-1">
									<Text
										margin="0px 0px 48px 0px"
										color="--dark"
										text-align="center"
										font="--base"
										width="max-content"
									>
										<Strong>
											מוגש עם ציפס ושתיה לבחירה{"\n\n"}
										</Strong>
									</Text>
								</Override>
							</Override>
						</Structure>
						<Button
							background="#007a12"
							quarkly-title="077"
							flex="0 0 auto"
							font="normal 300 15px/1.5 --fontFamily-googleAssistant"
							overflow-x="visible"
							overflow-y="visible"
							padding="10px 10px 10px 10px"
							margin="10px 0px 0px 10px"
							type="link"
							href="tel:0775008966"
							sm-width="max-content"
							display="flex"
							width="max-content"
							align-self="flex-start"
						>
							להזמנות חייגו{" "}
							<br />
							077-5008966
						</Button>
					</Box>
				</Box>
			</Section>
			<Section>
				<Box
					display="flex"
					grid-template-columns="repeat(3, 1fr)"
					grid-gap="32px 4%"
					md-grid-template-columns="1fr"
					quarkly-title="שורה1"
					margin="0px 0px 20px 0px"
					width="fit-content"
					sm-align-self="stretch"
					sm-flex-direction="column"
					min-width="100%"
					align-items="center"
				/>
			</Section>
		</Section>
		<Components.QuarklycommunityKitScrollIndicator />
		<Components.QuarklycommunityKitScrollIndicator />
		<Components.QuarklycommunityKitScrollIndicator />
		<Components.QuarklycommunityKitScrollIndicator />
		<Components.QuarklycommunityKitScrollIndicator />
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"639068b75f45170024239f06"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script place={"endOfHead"} rawKey={"661fae3033a5a44ee91af3c8"} />
			<script place={"endOfBody"} rawKey={"661fb2710a02415681b09f45"}>
				{"<script src=\"https://cdn.enable.co.il/licenses/enable-L9106k5bc614ivu-0721-58225/init.js\"></script>"}
			</script>
		</RawHtml>
	</Theme>;
});